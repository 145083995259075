<template>
  <v-app>
    <v-main class="align-center">
      <!-- 注册页 -->
      <v-container>
        <v-card max-width="520px" class="mx-auto px-16 align-center" elevation="10">
          <v-card-title class="justify-center pb-0">
            <v-img src="../assets/i/favicon.png" max-height="45" max-width="45"></v-img>
            <p class="ml-3 mt-2 headline">遥感工具平台</p>
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center">欢迎注册</v-card-subtitle>
          <v-divider></v-divider>
          <!-- 注册表单 -->
          <v-card-text class="">
            <v-tabs v-model="tab" centered hidden>
              <v-tab>基本信息</v-tab>
              <v-tab :disabled="isTabDisabled">其他信息</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item class="pt-4">
                <v-form ref="form">
                  <v-row no-gutters class="">
                    <v-col cols="12" sm="2" class="mt-2">用户名</v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field solo dense v-model="form.username" :rules="formRules.usernameRules"
                        @change="toLowCase"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="2" class="mt-2">密码</v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field solo dense type="password" v-model="form.password" :rules="formRules.passwordRules">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="2" class="mt-2">再次输入</v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field solo dense type="password" v-model="form.confirmPassword"
                        :rules="rules.confirmPasswordRules">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="2" class="mt-2">邮箱</v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field solo dense v-model="form.email" :rules="formRules.emailRules"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="2" class="mt-2">手机号</v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field solo dense v-model="form.phoneNumber" :rules="formRules.phoneNumberRules">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="2" class="mt-2">验证码</v-col>
                    <v-col cols="12" sm="5">
                      <v-text-field solo dense v-model="form.verificationCode" :rules="formRules.verificationCodeRules">
                      </v-text-field>
                    </v-col>
                    <!-- <v-spacer></v-spacer> -->
                    <v-col cols="12" sm="4" class="text-end" offset="1">
                      <v-btn color="grey lighten-1" :disabled="disable" @click="getCode">{{ codeText }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="2" class="mt-5">角色</v-col>
                    <v-col cols="12" sm="10">
                      <v-radio-group row dense v-model="radio">
                        <v-radio label="普通用户"></v-radio>
                        <v-radio label="项目管理员"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="radio == 0">
                    <v-col cols="12" sm="2" class="mt-2">邀请码</v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field solo dense v-model="form.invitationCode"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="justify-center">
                    <v-btn color="primary" @click="submit()" v-if="radio == 0">注册</v-btn>
                    <v-btn color="primary" @click="tab = 1" v-else>下一步</v-btn>
                  </v-row>
                  <!-- <v-row class="justify-center">
                    <v-btn color="primary" @click="
                        isTabDisabled = fasle;
                        tab = 1;
                      ">下一步</v-btn>
                  </v-row> -->
                </v-form>
              </v-tab-item>
              <v-tab-item class="pt-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="2" offset="1" class="mt-5">账号类型
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-radio-group row dense v-model="cerForm.type">
                      <v-radio label="个人用户"></v-radio>
                      <v-radio label="企业用户"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-form ref="cerForm">
                    <v-row no-gutters v-if="cerForm.type == 0">
                      <v-col cols="12" sm="2" offset="1" class="mt-2">姓名</v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field solo dense v-model="cerForm.name" :rules="rules.regisyerNameRuler"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" offset="1" class="mt-2">身份证号</v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field solo dense v-model="cerForm.uid" :rules="rules.regisyerIdCardRuler">
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="2" offset="1" class="mt-2">上传照片</v-col>
                      <v-col cols="12" sm="4">
                        <v-sheet min-height="80" class="grey lighten-4 d-flex align-center justify-center">
                          <v-file-input hide-input accept="image/*" prepend-icon="mdi-plus-circle"
                            class="pt-0 mt-0 justify-center" v-model="cerForm.frontPhoto" style="z-index:1000"
                            :rules="rules.regisyerFrontPhotoRuler" @change="uploadFrontPhoto()"></v-file-input>
                          <v-img :src="frontPhotoUrl" max-height="65" max-width="65" style="position:absolute;"></v-img>
                        </v-sheet>
                        <p class="text-center caption">身份证正面照</p>
                      </v-col>
                      <v-col cols="12" sm="4" offset="1">
                        <v-sheet min-height="80" class="grey lighten-4 d-flex align-center justify-center">
                          <v-file-input hide-input accept="image/*" prepend-icon="mdi-plus-circle"
                            class="pt-0 mt-0 justify-center" v-model="cerForm.backPhoto" style="z-index:1000"
                            :rules="rules.regisyerBackPhotoRuler" @change="uploadBackPhoto()"></v-file-input>
                          <v-img :src="backPhotoUrl" max-height="65" max-width="65" style="position:absolute;"></v-img>
                        </v-sheet>
                        <p class="text-center caption">身份证反面照</p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="cerForm.type == 1">
                      <v-col cols="12" sm="2" offset="1" class="mt-2">单位名</v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field solo dense v-model="cerForm.companyName" :rules="rules.regisyerCompanyNameRuler">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" offset="1" class="mt-2">工商执照</v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field solo dense v-model="cerForm.businessLicense"
                          :rules="rules.regisyerBusinessLicenseRuler"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" offset="1" class="mt-2">上传照片</v-col>
                      <v-col cols="12" sm="4">
                        <v-sheet min-height="80" class="grey lighten-4 d-flex align-center justify-center">
                          <v-file-input hide-input accept="image/*" prepend-icon="mdi-plus-circle"
                            class="pt-0 mt-0 justify-center" v-model="cerForm.businessLicensePhoto" style="z-index:1000"
                            :rules="rules.regisyerBusinessLicensePhotoRuler" @change="uploadBusinessLicensePhoto()">
                          </v-file-input>
                          <v-img :src="businessLicensePhotoUrl" max-height="65" max-width="65"
                            style="position:absolute;">
                          </v-img>
                        </v-sheet>
                        <p class="text-center caption">营业执照</p>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expand-transition>
                <v-row class="justify-center">
                  <v-col cols="12" sm="4" offset="2">
                    <v-btn color="primary" @click="tab = 0">上一步</v-btn>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-btn color="primary" @click="applyProjectAdmin()">申请</v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <v-row no-gutters align="center" class="mt-3">
              <v-col cols="12" sm="10" offset="2">
                <p class="caption text-end">
                  已经注册过账号?<v-btn text color="primary" @click="toLoginPage()">前往登录</v-btn>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- tips -->
        <v-snackbar v-model="snackbar" :color="color" :timeout="2000" :top="true">
          {{ text }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
import rules from "../config/rules";
// import md5 from "js-md5";
import commonCfg from "../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://localhost:64011/"; //api路径
// let version = ""; //api版本
let vm;
export default {
  data () {
    return {
      // tips
      snackbar: false,
      color: "error",
      text: "",
      // 短信验证码计时
      timer: null,
      disable: false,
      codeText: "获取验证码",
      second: 30,
      //注册表单
      form: rules.form,
      // 身份认证表单
      cerForm: {
        uid: "",
        name: "",
        companyName: "",
        businessLicense: "",
        businessLicensePhoto: [],
        frontPhoto: [],
        backPhoto: [],
        type: 0 // 0-个人用户，1-企业用户
      },
      // 注册表单验证
      formRules: rules.rules,
      rules: {
        confirmPasswordRules: [
          v => !!v || "确认密码为必填",
          v => v === this.form.password || "密码不匹配"
        ],
        regisyerNameRuler: [
          v => (this.cerForm.type == 0 && !!v) || "姓名为必填"
        ],
        regisyerIdCardRuler: [
          v => (this.cerForm.type == 0 && !!v) || "身份证为必填"
        ],
        regisyerFrontPhotoRuler: [
          v => (this.cerForm.type == 0 && v.length != 0) || "身份证正面照为必填"
        ],
        regisyerBackPhotoRuler: [
          v => (this.cerForm.type == 0 && v.length != 0) || "身份证背面照为必填"
        ],

        regisyerCompanyNameRuler: [
          v => (this.cerForm.type == 1 && !!v) || "公司名为必填"
        ],
        regisyerBusinessLicenseRuler: [
          v => (this.cerForm.type == 1 && !!v) || "工商执照为必填"
        ],
        regisyerBusinessLicensePhotoRuler: [
          v => (this.cerForm.type == 1 && v.length != 0) || "工商执照为必填"
        ]
      },

      tab: 0, //选中的tab下标
      isTabDisabled: true, //其他信息tab是否可用 为true时不可用
      radio: 0, //radio下标
      frontPhoto: null, //身份证正面照文件
      backPhoto: null, //身份证反面照文件
      businessLicensePhotoUrl: require("../assets/i/favicon.png"),
      frontPhotoUrl: require("../assets/i/favicon.png"), //身份证正面照图片地址
      backPhotoUrl: require("../assets/i/favicon.png") //身份证反面照图片地址
    };
  },
  created () {
    vm = this;
  },
  watch: {
    radio: function (val) {
      console.log(val);
      if (val == 0) {
        this.isTabDisabled = true;
      } else {
        this.isTabDisabled = false;
      }
    },
    form: {
      deep: true,
      handler (val) {
        val.username = val.username.toLowerCase();
      }
    }
  },
  methods: {
    toLoginPage () {
      this.$router.push("/login");
    },
    /**
     * keycloak 用户名不区分大小写
     */
    toLowCase () { },
    /**
     * 获取验证码
     */
    getCode () {
      if (this.form.phoneNumber.length == 11) {
        this.countDownTimer();
        let formData = new FormData();
        formData.append("mobile", this.form.phoneNumber);
        axios
          .post(`${commonUrl}pushSMS${version}`, formData)
          .then(res => {
            console.log(res);
            if (res.data.code == 200) {
              this.tips("green", "获取验证码成功");
            } else {
              this.tips("red", res.data.message);
            }
          })
          .catch(e => {
            console.log(e);
            this.tips("red", "获取验证码失败");
          });
      } else {
        this.tips("red", "手机号无效");
      }
    },
    // 重新获取验证码计时
    countDownTimer () {
      this.timer = setInterval(function () {
        vm.second -= 1;
        if (vm.second > 0) {
          vm.codeText = "重新发送(" + vm.second + ")";
          vm.disable = true;
        } else {
          vm.second = 30;
          clearInterval(vm.timer);
          vm.codeText = "获取验证码";
          vm.disable = false;
        }
      }, 1000);
    },
    submit () {
      if (this.$refs.form.validate()) {
        // let pws = md5(this.form.password);
        let param = {
          username: this.form.username,
          password: this.form.password,
          email: this.form.email,
          verificationCode: this.form.verificationCode,
          phoneNumber: this.form.phoneNumber,
          invitationCode:
            this.form.invitationCode == "" ? null : this.form.invitationCode
        };
        axios
          .post(`${commonUrl}addCustomer${version}`, param)
          .then(res => {
            if (res.data.code == "200") {
              this.tips("green", "注册成功！");
            } else if (res.data.message != null) {
              this.tips("red", res.data.message);
            } else {
              this.tips("red", "注册失败！");
            }
          })
          .catch(e => {
            console.log(e);
            this.tips("red", "注册失败！");
          });
      }
    },
    applyProjectAdmin () {
      if (this.$refs.form.validate()) {
        if (this.$refs.cerForm.validate()) {
          let formData = new FormData();
          // let pws = md5(this.form.password);
          formData.append("username", this.form.username);
          formData.append("password", this.form.password);
          formData.append("email", this.form.email);
          formData.append("verificationCode", this.form.verificationCode);
          formData.append("phoneNumber", this.form.phoneNumber);
          if (this.cerForm.type == 0) {
            formData.append("frontPhoto", this.cerForm.frontPhoto);
            formData.append("backPhoto", this.cerForm.backPhoto);
            formData.append("idCard", this.cerForm.uid);
            formData.append("type", this.cerForm.type);
            formData.append("name", this.cerForm.name);
          } else {
            formData.append(
              "businessLicensePhoto",
              this.cerForm.businessLicensePhoto
            );
            formData.append("businessLicense", this.cerForm.businessLicense);
            formData.append("type", this.cerForm.type);
            formData.append("companyName", this.cerForm.companyName);
          }
          axios
            .post(`${commonUrl}applyProjectAdmin${version}`, formData)
            .then(res => {
              if (res.data.code == "200") {
                this.tips("green", "注册成功！");
              } else if (res.data.message != null) {
                this.tips("red", res.data.message);
              } else {
                this.tips("red", "注册失败！");
              }
            });
        } else {
          console.log();
        }
      } else {
        this.tab = 0;
      }
    },
    // 更换身份证正面照片
    uploadFrontPhoto () {
      if (this.cerForm.frontPhoto.size > 1024 * 1024 * 2) {
        this.$emit("tips", "文件大小超过2M", "red");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.cerForm.frontPhoto);
      reader.onload = function () {
        console.log(reader.result);
        console.log(reader);
        //直接使用读取的结果
        vm.frontPhotoUrl = reader.result;
      };
    },
    // 更换身份证背面照片
    uploadBackPhoto () {
      if (this.cerForm.backPhoto.size > 1024 * 1024 * 2) {
        this.$emit("tips", "文件大小超过2M", "red");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.cerForm.backPhoto);
      reader.onload = function () {
        console.log(reader.result);
        console.log(reader);
        //直接使用读取的结果
        vm.backPhotoUrl = reader.result;
      };
    },
    // 更新营业执照
    uploadBusinessLicensePhoto () {
      if (this.cerForm.businessLicensePhoto.size > 1024 * 1024 * 2) {
        this.$emit("tips", "文件大小超过2M", "red");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.cerForm.businessLicensePhoto);
      reader.onload = function () {
        console.log(reader.result);
        console.log(reader);
        //直接使用读取的结果
        vm.businessLicensePhotoUrl = reader.result;
      };
    },
    tips (color, text) {
      this.snackbar = true;
      this.color = color;
      this.text = text;
    }
  }
};
</script>
<style>
.v-application .error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
</style>
